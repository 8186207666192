import { template as template_6fc8adce88964fd2895142a3cff7c297 } from "@ember/template-compiler";
const SidebarSectionMessage = template_6fc8adce88964fd2895142a3cff7c297(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
