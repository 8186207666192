import { template as template_07fae0e5b74f455a86db720df444283e } from "@ember/template-compiler";
const WelcomeHeader = template_07fae0e5b74f455a86db720df444283e(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
